<template>
  <div class="content-box">
    <!-- 轮播 -->
    <Carousel></Carousel>
    <!-- 智慧文旅 -->
    <div class="container-box">
      <div class="container-title">
        <span>智慧文旅</span>
        <span>3D技术、物联网、移动互联网、云计算、大数据</span>
      </div>
      <div class="or-container">
        <div class="box1" :class="eleindex == index ? 'eleactive' : ''" v-for="(item, index) in piclist" :key="index" @mouseenter="enter(index)" @mouseleave="out(index)" @click="Jump(item.path)">
          <div class="text-box" v-if="eleindex == index" :style="`
                background:url(${item.bg}) center center no-repeat;
                background-size:contain contain;
                height: 100%;
                `" alt="">
            <div class="text" v-if="eleindex == index">
              <h3>{{ item.title }}&nbsp;&nbsp;→</h3>
              <p>{{ item.text }}</p>
            </div>
          </div>
          <div class="text-box" v-else :style="`
                background:url(${item.bg}) center center no-repeat;
                background-size:contain contain;
                height: 100%;
                `" alt="">
            <div class="masks">
              <div class="lo-text">
                <img :src="item.loImg" alt="" />
                <span>{{ item.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 智慧政企 -->
    <div class="enterprise">
      <div class="enterprise-top">
        <span>智慧政企</span>
        <span>AI技术，联网和云计算技术，建立“物-人-数据”之间的信息交互和远程控制</span>
      </div>
      <div class="enterprise-box">
        <div class="enterprise-box-left" v-for="(item, index) of enterpriseData" :key="index">
          <div class="imgs">
            <!-- <img src="@/assets/imgs/enterpriseDefault.jpg" alt="" /> -->
            <!-- <img :src="item.contentImageId &&
              baseIP + imageShowAPI + item.contentImage.waterMarkUri
              " alt="" /> -->
            <img v-if="item.contentImageId &&item.contentImage" :src="item.contentImageId && baseIP + imageShowAPI + item.contentImage.waterMarkUri" alt="" />
            <img v-else :src="defaultImage" alt="" />
          </div>
          <div class="info">
            <h3>{{ item.title }}</h3>
            <!-- <p>
              {{ item.columnsContent }}
            </p> -->
            <p v-html="item.columnsContent"></p>
            <div class="details">
              <div class="logo"></div>
              <div class="check-details" @click="JumpDetails(item.secondColumnsName)">
                查看详情<i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 数字展陈 -->
    <div class="exhi-bition">
      <div class="video">
        <div class="video-info">
          <span>数字展陈</span>
          <i class="line"></i>
          <p>
            滑动触摸屏、360全息影像、雕塑投影、VR虚拟场景、多屏联动、虚拟翻书互动、互动一体机、模拟讲话投影
          </p>
        </div>
        <!-- <div class="video-component">
          <video-player
            class="video-player vjs-custom-skin size-box"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
          />
        </div> -->
        <div class="pictures-img">
          <CarouselPictures></CarouselPictures>
        </div>
      </div>
    </div>
    <!-- 产品中心 -->
    <div class="product-center">
      <div class="product-center-banner">
        <div class="product-info">
          <i>PRODUCT&nbsp;&nbsp;&nbsp;&nbsp;CENTER</i>
          <span>产品中心</span>
          <i class="line"></i>
        </div>
        <!-- 轮播图 -->
        <div class="product-carousel">
          <div class="prev">
            <img src="../../assets/imgs/left.png" alt="" @click="arrowClick('prev')" />
          </div>
          <el-carousel :interval="5000" :autoplay="true" arrow="never" ref="cardShow" loop>
            <el-carousel-item v-for="(items, indexs) of SoftwareData" :key="indexs">
              <div class="exhibit-img box" v-for="(item, index) of items" :key="index" @click="productJump(item.secondColumnsName, item.id)">
                <div class="img-box">
                  <!-- <img :src="item.columnsContentImages[0] && baseIP + imageShowAPI + item.columnsContentImages[0].image.waterMarkUri" alt="" /> -->
                  <!-- <img :src="item.contentImageId &&
                    baseIP + imageShowAPI + item.contentImage.waterMarkUri
                    " alt="" /> -->
                  <img v-if="item.contentImageId &&item.contentImage" :src="item.contentImageId && baseIP + imageShowAPI + item.contentImage.waterMarkUri" alt="" />
                  <img v-else :src="defaultImage" alt="" />
                </div>
                <div class="mask">
                  <span>{{ item.title }}</span>
                </div>
              </div>
            </el-carousel-item>
            <!-- <el-carousel-item v-if="SoftwareData2.length > 0">
              <div
                class="exhibit-img box"
                v-for="(item, index) of SoftwareData2"
                :key="index"
                @click="productJump(item.secondColumnsName, item.id)"
              >
                <div class="img-box">
                  <img :src="baseIP + imageShowAPI + item.imageUri" alt="" />
                </div>
                <div class="mask">
                  <span>{{ item.title }}</span>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item v-if="SoftwareData3.length > 0">
              <div
                class="exhibit-img box"
                v-for="(item, index) of SoftwareData3"
                :key="index"
                @click="productJump(item.secondColumnsName, item.id)"
              >
                <div class="img-box">
                  <img :src="baseIP + imageShowAPI + item.imageUri" alt="" />
                </div>
                <div class="mask">
                  <span>{{ item.title }}</span>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item v-if="SoftwareData4.length > 0">
              <div
                class="exhibit-img box"
                v-for="(item, index) of SoftwareData4"
                :key="index"
                @click="productJump(item.secondColumnsName, item.id)"
              >
                <div class="img-box">
                  <img :src="baseIP + imageShowAPI + item.imageUri" alt="" />
                </div>
                <div class="mask">
                  <span>{{ item.title }}</span>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item v-if="SoftwareData5.length > 0">
              <div
                class="exhibit-img box"
                v-for="(item, index) of SoftwareData5"
                :key="index"
                @click="productJump(item.secondColumnsName, item.id)"
              >
                <div class="img-box">
                  <img :src="baseIP + imageShowAPI + item.imageUri" alt="" />
                </div>
                <div class="mask">
                  <span>{{ item.title }}</span>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item v-if="SoftwareData6.length > 0">
              <div
                class="exhibit-img box"
                v-for="(item, index) of SoftwareData6"
                :key="index"
                @click="productJump(item.secondColumnsName, item.id)"
              >
                <div class="img-box">
                  <img :src="baseIP + imageShowAPI + item.imageUri" alt="" />
                </div>
                <div class="mask">
                  <span>{{ item.title }}</span>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item v-if="SoftwareData7.length > 0">
              <div
                class="exhibit-img box"
                v-for="(item, index) of SoftwareData7"
                :key="index"
                @click="productJump(item.secondColumnsName, item.id)"
              >
                <div class="img-box">
                  <img :src="baseIP + imageShowAPI + item.imageUri" alt="" />
                </div>
                <div class="mask">
                  <span>{{ item.title }}</span>
                </div>
              </div>
            </el-carousel-item> -->
          </el-carousel>
          <div class="next">
            <img src="../../assets/imgs/rigth.png" alt="" @click="arrowClick('next')" />
          </div>
        </div>
        <!-- 更多案例 -->
        <div class="more">
          <el-button type="danger" class="btn" @click="$router.push({ name: 'software' })">更多案例</el-button>
        </div>
      </div>
    </div>
    <!-- 新闻资讯 -->
    <div class="news">
      <div class="news-info">
        <span>新闻资讯</span>
        <i class="line"></i>
        <p>阅览最新闻资讯，感觉昱安最新动向动态</p>
      </div>
      <div class="news-content">
        <div class="news-content-left">
          <el-carousel arrow="always">
            <el-carousel-item v-for="(item, index) of bannerNews" :key="index">
              <!-- <img :src="item.contentImageId &&
                baseIP + imageShowAPI + item.contentImage.waterMarkUri
                " alt="" /> -->
              <img v-if="item.contentImageId &&item.contentImage" :src="item.contentImageId && baseIP + imageShowAPI + item.contentImage.waterMarkUri" alt="" />
              <img v-else :src="defaultImage" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="news-content-right">
          <div class="list-title" v-for="(item, index) of NewsText" :key="index" @click="toNews(item)">
            <!-- $router.push({ name: 'news',query:{newsData:item} }) -->
            <div class="text">
              <i class="el-icon-caret-right ic"></i>
              <span class="ic">{{ item.title }}</span>
              <em>{{ item.publishTime | timeFiltes }}</em>
            </div>
            <div class="list-title-text">
              <!-- {{ item.columnsContent }} -->
              <span v-html="item.columnsContent"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="partner">
      <div class="title">战略合作</div>
      <div class="partner-box">
        <span>
          <a href="https://epaper.southcn.com/nfdaily/html/202204/11/node_A01.html" target="_blank"><img src="../../assets/imgs/logo-南方日报.png" alt="" /></a>
        </span>
        <span>
          <a href="https://www.tsinghua.edu.cn/" target="_blank"><img src="../../assets/imgs/logo-清华大学.png" alt="" /></a>
        </span>
        <span>
          <a href="https://www.szu.edu.cn/" target="_blank"><img src="../../assets/imgs/logo-深圳大学.png" alt="" /></a>
        </span>
        <span>
          <a href="https://www.xmu.edu.cn/" target="_blank"><img src="../../assets/imgs/logo-厦门大学.png" alt="" /></a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import Carousel from "@/components/Carousel";
  import CarouselPictures from "@/components/CarouselPictures";
  import store from "@/store";
  import { getColumnContent, productCenter, getSmart } from "../../api/index.js";
  export default {
    data() {
      return {
        baseIP: process.env.VUE_APP_BASE_URL,
        imageShowAPI: "/yuan/files/getFileStream/",
        defaultImg: require("../../assets/imgs/enterpriseDefault.jpg"),  //   @/assets/img/enterpriseDefault.jpg
        defaultImage: require('../../assets/imgs/enterpriseDefault.jpg'),
        eleindex: 0,
        piclist: [
          {
            title: "智慧管理",
            text: "开发智慧博物馆的管理系统，如：综合管理平台、藏品管理系统、数字资源管理系统、OA自动化办公系统、观众行为统计分析系统、观众预约和票务系统",
            bg: require("../../assets/imgs/aa.png"),
            img: require("../../assets/imgs/aa.png"),
            loImg: require("../../assets/imgs/shezhi.png"),
            path: "smartManagement",
          },
          {
            title: "智慧保护",
            text: "提供全面的博物馆馆内文物的预防性保护方案及措施，以及提供相应的环境质量监测设备、环境调控设施、文物保存设施。",
            bg: require("../../assets/imgs/bb.jpg"),
            img: require("../../assets/imgs/bb.jpg"),
            loImg: require("../../assets/imgs/anquan.png"),
            path: "smartProtection",
          },
          {
            title: "智慧服务",
            text: "将通过LED大屏、全息互动柜等多方位互动展示，观众可通过扫码租用设备，智能导览器即可收听讲解，提供智能预约、购票、检票一体化系统实现博物馆智能化服务。",
            bg: require("../../assets/imgs/cc.jpg"),
            img: require("../../assets/imgs/cc.jpg"),
            loImg: require("../../assets/imgs/bangzhu.png"),
            path: "smartService",
          },
        ],
        playerOptions: {
          playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [
            {
              type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
              src: "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4", // url地址  http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4
            },
          ],
          poster: "", // 你的封面地址
          notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true, // 全屏按钮
          },
        },
        enterpriseData: [], // 智慧政企的数据
        SoftwareData: [],
        // SoftwareData2: [],
        // SoftwareData3: [],
        // SoftwareData4: [],
        // SoftwareData5: [],
        // SoftwareData6: [],
        // SoftwareData7: [],
        bannerNews: [], // 新闻资讯轮播图
        NewsText: [], // 新闻资讯列表数据
      };
    },
    created() {
      // 获取新闻
      this.getColumnContentNews("12");
      // 获取产品中心总的产品数据
      this.productCenter();
    },
    mounted() {
      this.$store.commit("ip/SET_routefullPath", this.$route.fullPath);
      // 获取数字展陈里面的发布到软件产品里的视频制作里面的视频
      this.getColumnContentvideo();
      // 获取智慧政企里面的置顶数据
      getSmart().then((res) => {
        console.log(res, "智慧政企");
        if (res.code === 0) {
          this.enterpriseData = res.data.content.slice(0, 2);
        }
      });
    },
    filters: {
      timeFiltes(val) {
        const starting = val.substr(0, 10);
        return starting;
      },
    },
    methods: {
      toNews(item) {
        // const items = JSON.stringify(item)
        this.$router.push({
          name: "news",
          query: { newsData: item.id, num: "news" },
        });
        localStorage.setItem("id", item.id);
        // this.$router.push({ name: 'news',query:{newsData:item,num: "news"} })
      },
      // 获取智慧政企里面的发布到智慧城市的数据
      getColumnContent(val) {
        getColumnContent({ optionType: val }).then((res) => {
          if (res.code === 0) {
            this.enterpriseData = res.data.content.slice(0, 2);
          }
        });
      },
      // 获取数字展陈里面的发布到软件产品里的视频制作里面的视频
      getColumnContentvideo() {
        getColumnContent({ optionType: "7" }).then((res) => {
          if (
            res.code === 0 &&
            res.data.content.length > 0 &&
            res.data.content[0].columnsContentFiles[0] != undefined
          ) {
            this.playerOptions.sources[0].src = "";
            this.playerOptions.sources[0].src =
              this.baseIP +
              this.imageShowAPI +
              res.data.content[0].columnsContentFiles[0].files.uri;
            console.log(res.data.content, "res.data.content");
            this.playerOptions.poster =
              this.baseIP + this.imageShowAPI + res.data.content[0].imageUri;
          }
        });
      },
      // 点击上一页或下一页获取产品中心的软件产品数据
      getColumnContentSoftware(val) {
        getColumnContent({ optionType: val }).then((res) => {
          if (res.code === 0) {
            this.SoftwareData = res.data.content.slice(0, 3);
            this.SoftwareData2 = res.data.content.slice(3, 6);
            this.SoftwareData3 = res.data.content.slice(6, 9);
            this.SoftwareData4 = res.data.content.slice(9, 12);
            this.SoftwareData5 = res.data.content.slice(12, 15);
            this.SoftwareData6 = res.data.content.slice(15, 18);
            this.SoftwareData7 = res.data.content.slice(18, 21);
          }
        });
      },
      // 获取产品中心总的产品数据
      productCenter() {
        productCenter().then((res) => {
          if (res.code === 0) {
            this.chejianYunXing = res.data.content;
            //处理成二维数组  [[],[],[]]
            let chejian = Math.ceil(this.chejianYunXing.length / 3);
            let newCheJian = [];
            for (let i = 0; i < chejian; i++) {
              newCheJian[i] = this.chejianYunXing.splice(0, 3);
            }
            this.SoftwareData = newCheJian;
            // this.SoftwareData = res.data.content.slice(0, 3);
            // this.SoftwareData2 = res.data.content.slice(3, 6);
            // this.SoftwareData3 = res.data.content.slice(6, 9);
            // this.SoftwareData4 = res.data.content.slice(9, 12);
            // this.SoftwareData5 = res.data.content.slice(12, 15);
            // this.SoftwareData6 = res.data.content.slice(15, 18);
            // this.SoftwareData7 = res.data.content.slice(18, 21);
          }
        });
      },
      // 获取新闻
      getColumnContentNews(val) {
        getColumnContent({ optionType: val }).then((res) => {
          if (res.code === 0) {
            // this.bannerNews = res.data.content.slice(0, 10);
            this.NewsText = res.data.content.slice(0, 10);
            const newData = res.data.content.slice(0, 10);
            newData.forEach((obj) => {
              if (obj.contentImageId) {
                this.bannerNews.push(obj);
              }
            });
          }
        });
      },
      // 移入
      enter(i) {
        this.eleindex = i;
      },
      // 移出
      out() {
        this.imgindex = -1;
      },
      // 产品中心轮播点击
      arrowClick(val) {
        if (val === "next") {
          // 获取产品中心的软件产品数据
          // this.getColumnContentSoftware("10");
          this.$refs.cardShow.next();
        } else {
          // 获取产品中心的软件产品数据
          // this.getColumnContentSoftware("9");
          this.$refs.cardShow.prev();
        }
      },
      // 智慧文旅跳转
      Jump(pathVal) {
        this.$router.push({ name: pathVal, query: { num: pathVal } });
      },
      JumpDetails(val) {
        if (val === "智慧城市") {
          this.$router.push({ name: "smartCity", query: { num: "smartCity" } });
        } else if (val === "数字政务") {
          this.$router.push({
            name: "DigitalGovernment",
            query: { num: "DigitalGovernment" },
          });
        } else {
          this.$router.push({ name: "glsClmBim", query: { num: "glsClmBim" } });
        }
      },
      // 产品中心跳转
      productJump(name, ids) {
        console.log(name);
        if (name === "软件产品" && name != null) {
          this.$router.push({
            name: "software",
            query: { num: "software", id: ids },
          });
        } else if (name === "硬件产品" && name != null) {
          this.$router.push({
            name: "hardware",
            query: { num: "hardware", id: ids },
          });
        } else {
          this.$message({
            type: "error",
            message: "数据错误",
          });
        }
      },
    },
    components: {
      Carousel,
      CarouselPictures,
    },
  };
</script>

<style lang="scss" scoped>
  .container-box {
    margin: 10px auto;
    margin-bottom: 72px;
  }

  .or-container {
    width: 1400px;
    height: 575px;
    margin: 0 auto;
    margin: 10px auto;
    display: flex;
    justify-content: center;

    .box1 {
      width: 231px;
      height: 575px;
      z-index: 50;
      float: left;
      margin: 0 5px;
      cursor: pointer;
      transition: all 0.2s;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .text-box {
      position: relative;

      .text {
        position: absolute;
        bottom: 26px;
        left: 10px;
        padding: 0 10px;
        color: #ffffff;

        h3 {
          height: 40px;
          line-height: 40px;
          font-weight: normal;
          text-align: left;
          font-size: 24px;
        }

        p {
          line-height: 30px;
          font-size: 16px;
          text-align: left;
        }
      }

      .masks {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;

        .lo-text {
          width: 100%;
          height: 49px;
          line-height: 49px;
          text-align: center;
          z-index: 1002;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          img {
            width: 33px;
            height: 41px;
            margin-bottom: 10px;
          }

          span {
            display: block;
            width: 100%;
            height: 22px;
            line-height: 22px;
            font-size: 22px;
            color: #ffffff;
          }
        }
      }
    }

    .eleactive {
      width: 918px;
      height: 575px;
    }
  }

  .container-title {
    width: 1400px;
    height: 150px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    span {
      display: inline-block;
      height: 40px;
      line-height: 40px;
    }

    span:nth-child(1) {
      font-size: 32px;
      color: #484848;
    }

    span:nth-child(2) {
      font-size: 16px;
      color: #8b8b8b;
    }
  }

  .enterprise {
    width: 100%;
    margin-bottom: 10px;

    .enterprise-top {
      width: 100%;
      height: 470px;
      background: url('../../assets/imgs/nnn.png') no-repeat;
      background-size: cover;

      span {
        display: block;
        height: 40px;
        line-height: 40px;
      }

      span:nth-child(1) {
        font-size: 32px;
        padding-top: 60px;
        color: #ffffff;
      }

      span:nth-child(2) {
        font-size: 16px;
        color: #ffffff;
      }
    }

    .enterprise-box {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .enterprise-box-left,
      .enterprise-box-right {
        width: 664px;
        position: relative;
        top: -250px;
      }

      .enterprise-box-left {
        box-shadow: 0px 3px 5px 0px rgba(56, 56, 56, 0.13);
        margin-right: 0px;
        width: 664px;
        // width: 670px;

        cursor: pointer;

        .imgs {
          width: 664px;
          height: 375px;
          display: flex; // 居中新加的
          align-items: center; // 居中新加的
          justify-content: center; // 居中新加的
          overflow: hidden;
          background-color: #f7f7f7;

          img {
            display: block;
            max-width: 100%; // 居中新加的
            max-height: 100%; // 居中新加的
            cursor: pointer;
            transition: all 0.9s;
          }

          img:hover {
            transform: scale(1.2);
          }
        }

        .info {
          background-color: #ffffff;
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          padding: 0 35px;
          box-sizing: border-box;

          h3 {
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: left;
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #333333;
            margin: 10px 0;
            margin-top: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          p {
            height: 60px;
            line-height: 17px;
            text-align: left;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #999999;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }

          .details {
            width: 100%;
            line-height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .logo {
              width: 140px;
              height: 45px;
              background: url('../../assets/imgs/xlogo.png') no-repeat;
              background-size: 100%;

              img {
                width: 136px;
                height: 31px;
              }
            }

            .check-details {
              color: #3b89e2;
              font-size: 18px;
              cursor: pointer;
            }
          }
        }
      }

      .enterprise-box-left:hover {
        z-index: 1000;

        .imgs {
          img {
            // -webkit-filter: contrast(50%); /*考虑浏览器兼容性：兼容 Chrome, Safari, Opera */
            // filter: contrast(50%);
            filter: brightness(50%);
          }
        }

        .info {
          background-color: #e83e3c;

          h3 {
            color: #ffffff;
          }

          p {
            color: #ffffff;
          }

          .logo {
            background: url('../../assets/imgs/xlogo1.png') no-repeat;
            background-size: 100%;
          }

          .check-details {
            color: #ffffff;
          }
        }
      }

      .enterprise-box-left:nth-child(1) {
        margin-right: 60px;
      }

      .enterprise-box-right {
        box-shadow: 0px 5px 10px 0px rgba(56, 56, 56, 0.13);

        .imgs {
          height: 410px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .info {
          background-color: #ffffff;
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-direction: column;
          padding: 0 43px;

          h3 {
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: left;
            font-size: 26px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #333333;
            margin: 10px 0;
          }

          p {
            line-height: 40px;
            text-align: left;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #999999;
          }

          .details {
            width: 100%;
            line-height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .logo {
              width: 140px;
              height: 45px;

              img {
                width: 136px;
                height: 31px;
              }
            }

            .check-details {
              color: #3b89e2;
              font-size: 18px;
              cursor: pointer;
            }
          }
        }

        box-shadow: 0px 3px 5px 0px rgba(56, 56, 56, 0.13);
      }
    }
  }

  .exhi-bition {
    height: 1149px;
    margin: 0 auto;
    margin-top: -170px;

    img {
      width: 100%;
      height: 100%;
    }

    .video {
      width: 1289px;
      height: 900px;
      margin: 0 auto;
      padding-top: 100px;

      .video-info {
        height: 105px;
        line-height: 105px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        span {
          display: inline-block;
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 36px;
          color: #ffffff;
          margin-bottom: 15px;
        }

        p {
          display: inline-block;
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          color: #ffffff;
          margin-top: 20px;
        }

        .line {
          width: 50px;
          border-bottom: 3px solid #ffffff;
        }
      }

      .video-component {
        width: 1289px;
        height: 749px;
        line-height: 749px;
        margin-top: 54px;

        .size-box {
          width: 1289px;
          height: 749px;
        }
      }

      /deep/ .pictures-img {
        width: 1278px;
        height: 749px;
        line-height: 180px;
        margin-top: 54px;
      }

      /deep/ .video-js {
        background-color: transparent;
      }

      /deep/ .vjs-poster {
        background-size: cover;
      }
    }

    background-image: url('../../assets/imgs/bj1.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
  }

  .product-center {
    width: 100%;
    height: 742px;
    background-image: url('../../assets/imgs/changping.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;

    .product-center-banner {
      height: 100%;
      width: 1200px;
      margin: 0 auto;
    }

    .product-info {
      height: 105px;
      line-height: 105px;
      margin: 0 auto;
      margin-bottom: 74px;
      padding-top: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      i {
        font-style: normal;
        height: 40px;
        line-height: 40px;
        font-size: 24px;
        font-weight: lighter;
        color: #959595;
      }

      span {
        display: inline-block;
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 34px;
        color: #333333;
        margin-bottom: 20px;
      }

      .line {
        width: 50px;
        border-bottom: 3px solid #333333;
      }
    }

    /deep/ .product-carousel {
      height: 300px;
      position: relative;

      .prev {
        width: 66px;
        height: 120px;
        z-index: 100;
        text-align: center;
        position: absolute;
        left: -126px;
        top: 50%;
        margin-top: -60px;

        img {
          width: 66px;
          height: 120px;
          cursor: pointer;
        }
      }

      .next {
        width: 66px;
        height: 120px;
        z-index: 100;
        text-align: center;
        position: absolute;
        right: -126px;
        top: 50%;
        margin-top: -60px;

        img {
          width: 66px;
          height: 120px;
          cursor: pointer;
        }
      }

      .el-carousel__indicators--horizontal {
        display: none;
      }

      .exhibit-img {
        width: 372px;
        height: 277px;
        line-height: 277px;
        background-color: #fff;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .img-box {
          // width: 260px !important;
          // height: 200px;
          height: 100%;

          // line-height: 200px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .exhibit-img:nth-child(2) {
        margin: 0 40px;
      }

      .el-carousel__item {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .box {
      position: relative;
      display: block;
      overflow: hidden;

      img {
        display: block;
      }

      .mask {
        width: 100%;
        height: 100%;
        position: absolute;
        top: -170px;
        opacity: 0;
        transition: all 0.6s;
        color: #fff;
        text-align: center;
        z-index: 2;

        span {
          font-size: 18px;
        }
      }
    }

    .box:hover .mask {
      top: 0;
      opacity: 1;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .more {
    .btn {
      font-size: 20px;
    }

    /deep/ .el-button {
      span {
        display: inline-block;
        margin-top: 3px;
      }
    }
  }

  .news {
    width: 1400px;
    height: 642px;
    margin: 0 auto;

    .news-info {
      height: 105px;
      line-height: 105px;
      margin: 0 auto;
      margin-top: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      span {
        display: inline-block;
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 40px;
        color: #333333;
        margin-bottom: 20px;
      }

      p {
        display: inline-block;
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        color: #999999;
      }

      .line {
        width: 50px;
        border-bottom: 3px solid #333333;
      }
    }

    .news-content {
      width: 1400px;
      height: 448px;
      margin: 0 auto;
      margin-top: 30px;
      display: flex;
      justify-content: left;
      align-items: center;
    }

    /deep/ .news-content-left {
      width: 710px;
      height: 448px;
      margin-right: 35px;

      .el-carousel__container {
        height: 448px;
      }

      .el-carousel__indicators--horizontal {
        display: none;
      }

      img {
        width: 710px;
        height: 448px;
      }
    }

    /deep/ .news-content-right {
      width: 627px;
      height: 430px;
      overflow: hidden;

      // margin-top: 29px;
      // padding-top: 20px;
      .list-title {
        height: 22px;
        line-height: 22px;
        font-size: 18px;
        cursor: pointer;
        text-align: left;
        overflow: hidden;
        margin-bottom: 20px;
        transition: all 0.9s;

        .text {
          display: flex;
          justify-content: left;
          align-items: center;

          i {
            font-style: normal;
            color: #333333;
          }

          span {
            display: block;
            width: 400px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          em {
            // width: 100px;
            text-align: center;
            font-style: normal;
            font-size: 16px;
            float: right;
          }
        }
      }

      .list-title:hover {
        height: 90px;
        display: block;

        i {
          transform: rotate(90deg);
          transition: 0.5s;
        }
      }

      .list-title-text {
        height: 45px;
        width: 520px;
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        text-align: left;
        color: #999999;
        text-indent: 2em;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        // overflow: hidden;
      }

      .show {
        height: 120px;
        display: block;
      }
    }
  }

  .partner {
    width: 1400px;
    margin: 0 auto;
    margin-bottom: 40px;

    .title {
      font-size: 24px;
      text-align: left;
      color: #010000;
      border-bottom: 1px solid #e5e5e5;
    }

    .partner-box {
      margin-top: 20px;
      display: flex;
      justify-content: left;
      align-items: center;

      span {
        display: inline-block;
        margin-right: 30px;
        border: solid 1px #e5e5e5;

        a {
          display: inline-block;
          margin: 5px;

          img {
            height: 40px;
          }
        }
      }
    }
  }
</style>
<template>
  <div class="banner">
    <el-carousel :interval="5000" arrow="always">
      <el-carousel-item>
          <img
            src="../assets/imgs/img-box.jpg"
            alt=""
            class="banner-image"
          />
      </el-carousel-item>
      <el-carousel-item>
          <img
            src="../assets/imgs/img-box1.jpg"
            alt=""
            class="banner-image"
          />
      </el-carousel-item>
      <el-carousel-item>
          <img
            src="../assets/imgs/img-box2.jpg"
            alt=""
            class="banner-image"
          />
      </el-carousel-item>
      <el-carousel-item>
          <img
            src="../assets/imgs/img-box3.jpg"
            alt=""
            class="banner-image"
          />
      </el-carousel-item>
      <el-carousel-item>
          <img
            src="../assets/imgs/img-box4.jpg"
            alt=""
            class="banner-image"
          />
      </el-carousel-item>
      <el-carousel-item>
          <img
            src="../assets/imgs/img-box5.jpg"
            alt=""
            class="banner-image"
          />
      </el-carousel-item>
      <el-carousel-item>
          <img
            src="../assets/imgs/img-box6.jpg"
            alt=""
            class="banner-image"
          />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import store from "@/store";
import { getBanner } from "@/api/index.js";
export default {
  data() {
    return {
      baseIP: process.env.VUE_APP_BASE_URL,
      imageShowAPI: "/yuan/files/getFileStream/",
      banners: [],
    };
  },
  created() {
    // this.getBanner();
  },
  methods: {
    // 获取轮播图数据
    getBanner() {
      getBanner().then((res) => {
        if (res.code === 0) {
          this.banners = res.data.content;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  // height: 400px;
  height: 100%;
 /deep/ .banner-image {
    width: 100%;
    height: 100%;
  }
  /deep/ .el-carousel-item{
    width: 100%;
    
  }
  /deep/ .el-carousel__indicators{
  display: none;
}
  /deep/ .el-carousel__container {
    // height: 300px;
    //  width: 710px;
    height: 414px;
    margin: 0 auto;
  }
}
.imgs ::v-deep {
  width: 100%;
}
/deep/ .el-carousel__arrow--left {
  left: 40px;
  width: 60px;
  height: 60px;
  .el-icon-arrow-left {
    font-size: 28px;
  }
}
/deep/ .el-carousel__arrow--right {
  right: 40px;
  width: 60px;
  height: 60px;
  .el-icon-arrow-right {
    font-size: 28px;
  }
}
</style>